import React from "react";

const PrivacyContent = () => {
  return (
    <div className={`mb-4 leading-5 `}>
      <span className="whitespace-break-spaces text-sm">
        {"The service is owned and operated by the HEYMDAL STUDIO AS, which is responsible for the processing of personal information that is collected when you use the Service. We comply with the applicable privacy regulations at all times in our processing of your personal data.\n" +
          "\n" +
          "The legal basis for the processing of personal data in HEYMDAL is your use of the Service and acceptance of our terms of use.\n" +
          "\n" +
          "1. What personal data does HEYMDAL process?\n" +
          "In order to use parts of the Service, you must create a user account .\n" +
          "\n" +
          "When you create a user account, we only ask for your email address. You must also choose a password that you will use to log in later. This password is encrypted and cannot be read by others.\n" +
          "\n" +
          "In addition to your personal information associated with your user account, we store and process data about which markers you have created under “Profile” and what you have stored in your lists. Public and private content such as comments, pictures and markers are stored.\n" +
          "\n" +
          "2. What is the personal information used for?\n" +
          "The personal information is primarily used to handle and communicate with you about your use of the Service, as long as you have not given us consent to anything else.\n" +
          "\n" +
          "Furthermore, we collect and process information when you use our services in order to analyze usage patterns, market trends, improve our services and to prevent and detect abuse. With your consent, we will also use this information to be able to customize content and offer you targeted information, offers and advertising based on behaviors and assumptions about preferences.\n" +
          "\n" +
          "We may use your personal information for the following purposes:\n" +
          "\n" +
          "    To create and manage a user account associated with the Service\n" +
          "    To process inquiries via our digital services\n" +
          "    To be able to communicate with you in connection with your use of the Service\n" +
          "    To analyze the information so that we, with your consent, can send you relevant offers and information.\n" +
          "    To send you surveys that give you the opportunity to influence the development of the Service\n" +
          "    To test and improve the systems we use to offer services\n" +
          "    To prevent misuse or misuse of our services\n" +
          "\n" +
          "We will only retain your information for as long as is necessary to fulfill the purpose of the processing, or as long as the law requires us to retain it. After this, your personal information will be deleted or anonymized. Anonymous information can not be traced back to you, and will be used for statistics and other purposes that can help us improve and further develop our services.\n" +
          "\n" +
          "3. Cookies and analysis tools\n" +
          "We use various analysis tools and store cookies on the Service to measure traffic, to register the type of content and services our visitors are interested in, and to improve and simplify your use of our services.\n" +
          "\n" +
          "Cookies are small text files that are stored in the browser of your computer or mobile device. We do not use cookies to store personal information, and your use of the Service can not be traced back to you. By visiting and using the Service, you agree that we use cookies, so that we can give you a better user experience. If you wish, you can set your browser so that it does not accept the use of cookies. Through the browser you can also delete all stored cookies.\n" +
          "\n" +
          "We use Google Analytics to register and analyze anonymous statistics for visits to HEYMDALs homepage. For each page you visit, information is stored such as date and time, which page you came from, which page you went to, which browser you use, etc. We also use the following analysis tools and third-party solutions:\n" +
          "\n" +
          "4. Disclosure of personal information\n" +
          "We do not sell personal information for marketing purposes to other companies. We can only share your personal information in the following cases:\n" +
          "\n" +
          "    our partners, once you have agreed to it,\n" +
          "    with our third party service providers, who perform tasks or work on our behalf and in accordance with our instructions as data processors, for the purposes set out in this privacy statement,\n" +
          "    to protect and defend the Service (including enforcing applicable terms of use), or\n" +
          "    with public authorities or agencies when required by law, court decision or final decision of the authorities.\n" +
          "\n" +
          "5. Links to third parties\n" +
          "This privacy statement only applies to HEYMDAL use, collection and disclosure of personal information. The service may contain links to other websites that are not under our control. HEYMDAL STUDIO AS is not responsible for the content of these websites and is not responsible for any processing of personal data on these websites. HEYMDAL STUDIO AS offers the links to make it easier for our visitors to find more information on specific topics.\n" +
          "\n" +
          "6. How do we protect your personal information?\n" +
          "We have implemented technical and organizational measures to protect your information so that it does not disappear, be manipulated or that unauthorized persons gain access to it. We continuously adapt the security measures to the technological development.\n" +
          "\n" +
          "Personal information that is registered or presented in the Service is sent in encrypted form. This means that the information passes through a secure connection and that your personal information cannot be read by external parties.\n" +
          "\n" +
          "7. Your rights\n" +
          "You have the right to request information about the personal information we hold about you at any time. If the information is incorrect, incomplete or irrelevant, you can request that the information be corrected or removed. Inquiries about access, correction and deletion will be answered continuously and no later than within 30 days. We can not remove your information if there are statutory requirements for this to be stored, such as. accounting rules or other legitimate reasons for retaining the information.\n" +
          "\n" +
          "8. Changes to this Privacy Statement\n" +
          "HEYMDAL STUDIO AS may revise this privacy statement when the processing of personal data changes or when revision is necessary due to changes in current legislation. When we make changes to this statement, we will change the revision date, and the amended privacy statement will take effect from the last revision date. We will make major changes clearly visible, but recommend that you read the privacy statement regularly when using the Service. In addition, we will refer to the change history in our privacy statement.\n" +
          "\n" +
          "9. Contact\n" +
          "HEYMDAL STUDIO AS\n" +
          "\n" +
          "Sigrid Undsets Vei 7D\n" +
          "\n" +
          "0669 Oslo\n" +
          "\n" +
          "E-mail: heymdal.app@gmail.com"}
      </span>
    </div>
  );
};

export default PrivacyContent;
