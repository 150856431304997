import React from "react";

import { useIntl } from "react-intl";
import Divider from "components/shared/divider";

const TermsTitle = () => {
  const intl = useIntl();

  return (
    <div className="prose max-w-5xl">
      <div className="italic">Last updated May 12, 2022</div>
      <Divider />
      <h1 className="m-0 mb-2 text-3xl text-slate-700">
        End User License Agreement
      </h1>
      <div className="text-sm font-bold">
        {intl.formatMessage({ id: "termsSubtitle1" })}
      </div>
      <Divider />
    </div>
  );
};
export default TermsTitle;
