import * as React from "react";
// import { useIntl } from "react-intl";

const PageNotFound = () => {
  // const intl = useIntl();

  return (
    <div className="prose mx-auto flex h-full max-w-6xl flex-col items-center justify-center px-10 py-32 text-center md:py-3">
      <h1 className="m-0 text-5xl">404</h1>
      <h2 className="mt-4">Oops, that page does not exist</h2>
      <div>This page may have have been deleted or the URL does not exist.</div>
    </div>
  );
};

export default PageNotFound;
