import {appNavigationLinks} from "components/nav-bar/components/nav-bar-items";
import React from "react";

import {useIntl} from "react-intl";
import {NavLink} from "react-router-dom";

const FooterMainLinks = () => {
  const intl = useIntl();

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  return (
    <div className="pr-4">
      {appNavigationLinks.map((link) => (
        <NavLink
          to={link.path}
          key={link.intlId}
          className="mb-2 block"
          onClick={scrollToTop}
        >
          {intl.formatMessage({id: link.intlId})}
        </NavLink>
      ))}
    </div>
  );
};
export default FooterMainLinks;
