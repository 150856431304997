import React from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";

type Props = {
  to: string;
  title: string;
};

const NavBarLinkItem = ({ to, title }: Props) => {
  const intl = useIntl();
  const homeText = intl.formatMessage({ id: "home" });
  return (
    <NavLink
      to={to}
      className="flex h-16 items-center pl-4 font-bold leading-snug transition-all hover:text-primary md:px-3 md:py-2"
    >
      <span
        className={`ml-2 text-lg ${title === homeText ? "text-primary" : ""}`}
      >
        {title}
      </span>
    </NavLink>
  );
};

export default NavBarLinkItem;
