import clsx from "clsx";
import * as React from "react";
import LoadingSpinner from "utils/loading-spinner";

interface ButtonProps {
  variant?: "primary" | "secondary";
  size?: "small" | "medium" | "large";
  text: string;
  onTap: () => void;
  leading?: React.ReactNode | React.ReactNode[];
  trailing?: React.ReactNode | React.ReactNode[];
  className?: string;
  isLoading?: boolean;
  rounded?: boolean;
}

const Button = ({
  variant = "primary",
  size,
  text,
  onTap,
  leading,
  trailing,
  className,
  rounded = false,
  isLoading = false,
}: ButtonProps) => {
  const variantStyles = {
    primary: "bg-primary hover:bg-green-100 hover:text-primary",
    secondary: "bg-secondary",
  };

  const buttonStyle = clsx(
    `inline-block flex justify-center border-2 border-solid border-primary bg-primary px-7 py-2 font-semibold text-onPrimary no-underline transition-all duration-[0.2s] ${
      rounded ? "rounded-full" : "rounded-md"
    } ${variantStyles[variant]} ${
      isLoading
        ? "pointer-events-none cursor-wait opacity-75 hover:"
        : "cursor-pointer"
    }`,
    className
  );

  return (
    <div>
      <button className={buttonStyle} onClick={onTap}>
        <div className="flex space-x-2">
          {<LoadingSpinner isLoading={isLoading} />}
          {leading}
          <div>{text}</div>
          {trailing}
        </div>
      </button>
    </div>
  );
};
export default Button;
