import React from "react";
import { images } from "utils/images";

type Props = {
  onTap: () => void;
};

const BurgerButton = ({ onTap }: Props) => {
  return (
    <button
      className="block cursor-pointer rounded border border-solid border-transparent bg-transparent px-3 py-1 text-xl leading-none text-white outline-none focus:outline-none md:hidden"
      type="button"
      onClick={onTap}
    >
      <img src={images.burgerMenuIcon} alt="Burger menu icon" className="w-6" />

      <i className="fas fa-bars"></i>
    </button>
  );
};
export default BurgerButton;
