export const languageCodes: Record<string, string> = {
  english: "en-GB",
  norwegian: "nb-NO",
};

type Messages = {
  [key in keyof typeof languageCodes]: Record<string, string>;
};

function getKeyByValue(object: Record<string, string>, value: string): string {
  return (
    Object.keys(object).find((key) => object[key] === value) ?? "norwegian"
  );
}

export const getLanguageMessages = (languageCode: string) =>
  messages[getKeyByValue(languageCodes, languageCode)];

export const messages: Messages = {
  english: {
    pageNotFound: "Page not Found",
    languageName: "English",
    menu: "Menu",
    about: "About",
    home: "Home",
    contact: "Contact",
    forApple: "For Apple",
    forAndroid: "For Android",
    features: "Features",
    name: "Name",
    email: "Email",
    message: "Message",
    send: "Send",
    featuresSummaryDescription1:
      "Here is a summary of the amazing things you can do with the app",
    downloadSummaryDescription1: "Download and share your first trip.",
    downloadSummaryDescription2: "Save time, money, and the environment.",
    mainTitleDescription1:
      "Get compensated as a driver or join a trip for less than public transportation.",
    mainTitleDescription2: "A faster, cheaper, and more flexible solution!",
    share: "Share",
    a: "a",
    ride: "ride",
    contactInfo: "Contact info",
    contactInfoDescription1:
      "For customer service or business related inquiries, please contact us using the form below.",
    to: "to",
    work: "work",
    university: "university",
    theCabin: "the cabin",
    theBeach: "the beach",
    hometown: "hometown",
    howItWorks: "How it works",
    howItWorksForPassengersTitle1:
      "Find a driver who's travelling in the same direction",
    howItWorksForPassengersTitle2:
      "Meet at the planned time, place and start your trip",
    howItWorksForPassengersTitle3: "Rate your experience",
    howItWorksForPassengersSubtitle1:
      "Use our platform to find a driver who's heading in the same direction. Booking and payment is all done through the app.",
    howItWorksForPassengersSubtitle2:
      "Start your trip. Talk, listen to music, or enjoy the trip without talking. It's all up to you.",
    howItWorksForPassengersSubtitle3:
      "After the trip you can review the driver for other members of the platform to see.",
    howItWorksForDriversTitle1:
      "Offer a trip and cover parts of your travel costs",
    howItWorksForDriversTitle2: "You're in control",
    howItWorksForDriversTitle3:
      "Pick-up passengers at the agreed time and place, and start your trip",
    howItWorksForDriversTitle4: "Rate your passengers",
    howItWorksForDriversSubtitle1:
      "With ridesharing you are able to cover parts of your travel cost, and contribute to more environmental friendly transportation. Furthermore, you provide people with an alternative mode of transportation with increased flexibility and reduced price.",
    howItWorksForDriversSubtitle2:
      "Create your trip and select a price based on our algorithm. You control the rules for your trip and those allowed to join.",
    howItWorksForDriversSubtitle3:
      "Say hello, pack the car, and start your trip. Talk, listen to music, or enjoy the silence. No two rides are the same, but all are good.",
    howItWorksForDriversSubtitle4:
      "After the trip you can rate your passengers for other members of the platform to see.",
    forPassengers: "For passengers",
    forDrivers: "For drivers",
    anyQuestions: "Any questions",
    showMore: "Show more",
    showLess: "Show less",
    aboutUsDescription1:
      "Trips is a platform built around the vision of making transportation more flexible, affortable and environmental friendly. In comparrison to other services, such as Uber and Bolt, Trips centers around the principle of ridesharing and compensation of travel expenses. The amount of compensation is calculated based on travel distance and the Norwegian standard numeration." +
      "\n" +
      "\n" +
      "Behind the platform stands a small team with cross-functional competence from the Norwegian tech industry. Our goal is to build a product that is easy-to-use, practical, and genuinely useful. The platform is developed with the purpose of solving issues in modern society. Furthermore, the platform fee from every trip is only meant to cover operational expenses and not result in company profits. We hope your can join us in our journey! ",
    currentRides: "Current rides",
    privacy: "Privacy",
    privacyTitle1: "Privacy Policy",
    privacySubtitle1: "",
    privacy_point_1_title: " ",
    privacy_point_2_title: " ",
    privacy_point_3_title: " ",
    privacy_point_4_title: " ",
    privacy_point_5_title: " ",
    privacy_point_6_title: " ",
    privacy_point_7_title: " ",
    privacy_point_8_title: " ",
    privacy_point_1_subtitle: " ",
    privacy_point_2_subtitle: " ",
    privacy_point_3_subtitle: " ",
    privacy_point_4_subtitle: " ",
    privacy_point_5_subtitle: " ",
    privacy_point_6_subtitle: " ",
    privacy_point_7_subtitle: " ",
    privacy_point_8_subtitle: " ",
    users: "Users",
    ridesShared: "Rides shared",
    kgOfCo2Saved: "kg of C02 saved",
    messageSent: "Message sent",
    lastUpdatedDateTerms1: "Last updated: May 23rd, 2023",
    lastUpdatedDatePrivacy1: "Last updated: June 15th, 2023",
    terms: "Terms",
    termsTitle1: "Terms of Service",
    termsSubtitle1:
      "By using the services on this website you accept the following terms and conditions.",
    terms_point_1_title: "Introduction",
    terms_point_1_subtitle:
      "By using the Trips app, the User accepts to be bound by the terms and provisions of the agreement made between the User and Heymdal Studio AS, a company registered under Norwegian law, with organization number 928958515. This includes being subject to and complying with all guidelines and rules that apply to the Service. If you do not agree to be bound by this agreement, all use of the Trips app is prohibited. Any participation in, or use of, the Trips app will therefore constitute acceptance of this agreement.\n" +
      "\n" +
      "Trips is a matching service that enables Users to get in touch, plan and share the travel cost for carpooling. The Service is not a commercial transport solution. Users enter into direct agreements with each other, and Heymdal Studio is not a party to any agreements between Users.\n" +
      "Drivers and Passengers cannot send or take with them things that are illegal to transport.\n" +
      "\n" +
      "As a User of this Service, you approve communications from Heymdal Studio.\n" +
      "\n",
    terms_point_2_title: "Scope of Service",
    terms_point_2_paragraph_1:
      "Heymdal Studio AS is the sole owner of all intellectual property rights related to this Service, including but not limited to source code, logos, videos, sounds, designs, images, text and data. Subject to compliance with these Terms of Agreement, Heymdal Studio AS grants you a non-exclusive, non-transferable and personal license to use the Platform and Services.",
    terms_point_2_paragraph_2:
      "You agree and accept that content and data provided by you on the Platform can be used and translated by Heymdal Studio AS.",
    terms_point_2_paragraph_3:
      "You may not share or make the Application available to third parties, sell, rent, lend or lease or otherwise redistribute the Application.",
    terms_point_2_paragraph_4:
      "You may not reverse engineer, translate, disassemble, integrate, decompile, remove, modify, combine, create derivative works or updates of, adapt, or attempt to derive the source code of the Application, or any part thereof.",
    terms_point_2_paragraph_5:
      "Heymdal Studio reserves the right to modify the terms and conditions of licensing.",
    terms_point_2_paragraph_6:
      "By registering on the Platform, you acknowledge and accept that Heymdal Studio may use and store some of your personal data. Heymdal Studio can contact you with news and updates. More information can be found in our privacy policy.",
    terms_point_3_title: "Registration",
    terms_point_3_subtitle:
      "To register an Account on the Platform, You must have read and accepted the terms of the agreement and our privacy policy.\n" +
      "By registering on the Platform, you warrant that you are 18 years of age or older. The application utilizes Vipps to facilitate a secure registration process. All users are therefore required to have a Vipps account to use the Platform.\n" +
      "\n" +
      "In the registration, You agree to provide accurate information at all times on your profile. Heymdal Studio is not responsible for Users providing accurate and correct information about identity, or other information posted on this Platform. Trips reserves the right to block an Account if the User does not provide accurate and truthful information.",
    terms_point_4_title: "Usage",
    terms_point_4_paragraph_1:
      "Usage for Drivers\n" +
      "When you post a Ride as a Driver on the Platform, you are solely responsible for ensuring that everything related to your Ride is accurate and truthful. You are bound to carry out your journey as informed in your Ride, including waiting at least 10 minutes for passengers at the planned pick-up point. Heymdal Studio may remove your Ride without warning, if it does not follow the guidelines described in the Terms and Conditions.\n" +
      "\n" +
      "When you post a Ride, you agree to follow the laws and guidelines of Norway. In addition, it includes that you as a Driver:\n" +
      "\n" +
      "\t · \t Not being used for, or involved in, illegal activity or the transportation of illegal goods;\n" +
      "\t · \t Have a valid driver's license;\n" +
      "\t · \t Are in good health and not under the influence of medication, alcohol or drugs;\n" +
      "\t · \t Have a car that is in legal condition and insured;\n" +
      "\t · \t Either own, or are allowed to borrow the car you use;\n" +
      "\t · \t Stores luggage and other items in a manner that does not affect the Passenger's comfort or safety;",
    terms_point_4_paragraph_2:
      "Usage for Passengers\n" +
      "You agree to provide true and accurate information to other Users. You are responsible to participate and complete the journey as informed in the Ride, including waiting at least 10 minutes after the agreed arrival time for the Driver at the planned pick-up point. Passengers are responsible for not using the Service for illegal activities or sending prohibited goods. \n" +
      "\n" +
      "This Service is only a Platform where Users can enter into direct agreements with each other. Heymdal Studio is not part of agreements between Users. Heymdal Studio is also not responsible for what is transported or how it is done, this is a matter between Users in separate agreements.\n" +
      "\n" +
      "All illegal use of this Service must be reported.",
    terms_point_5_title: "Reviews",
    terms_point_5_subtitle:
      "Heymdal Studio encourages Users to leave a review after completing their ride. The review must be factual and not based on inappropriate feelings. All swearing, references to race, threats, vulgar language, personal attacks and other language covered by the legislation in Norway are strictly prohibited and may result in blocking from the Platform. You agree that Heymdal Studio reserves the right to delete or not publish reviews or responses that do not comply with these terms, or for other reasons deemed inappropriate based on reputation, legality or other justified reasons.\n" +
      "Heymdal Studio reserves the right to terminate any Account, based on ratings and reviews.",
    terms_point_6_title: "Ride cancellation",
    terms_point_6_subtitle:
      "After the request has been confirmed, the Ride can be canceled by both the Driver and the Passenger.",
    terms_point_6_paragraph_1:
      "If the Driver cancels the Trip, or does not show up at the pick-up point within reasonable boundaries after the agreed time, the Passenger will be refunded the total amount paid, including the Service Fee.\n" +
      "\n" +
      "If the Passenger cancels the Ride, it depends on when the cancellation is made. ",
    terms_point_6_paragraph_2:
      "If cancellation is made more than 24 hours before scheduled departure, the Passenger will be reimbursed the total amount paid, excluding the Service Fee. ",
    terms_point_6_paragraph_3:
      "If cancellation is made 24 hours or less before departure, the total amount paid is split 50/50 between Driver and Passenger, after the Service Fee is subtracted from the total amount paid.",
    terms_point_6_paragraph_4:
      "If cancellation is made 4 hours or less before departure, no refund will be given. The driver receives the total amount paid excluding the Service Fee.",
    terms_point_6_paragraph_5:
      "If, on the other hand, the booking was made during the 24 hours before departure, but is canceled less than 30 minutes after booking, the same rules apply as for cancellations before 24 hours. ",
    terms_point_6_paragraph_6:
      "If the Passenger cancels after the scheduled departure time, no refund will be given. The driver receives the total amount paid excluding the Service Fee. The same applies if the Passenger does not show up at the meeting point 10 minutes after the agreed time.",
    terms_point_7_title: "Transaction & Service Fee",
    terms_point_7_subtitle:
      "We use Vipps as our payment processor. In order to make and/or receive payments on this Platform, you must accept sharing your bank details with Heymdal Studio. \n" +
      "\n" +
      "It is strictly forbidden to profit from the transport of Passengers via the Platform. Profit occurs if you as the User asks for a price above self-cost. Heymdal Studio suggests a price for the Ride based on distance and governmental metrics for mileage allowance, you acknowledge and accept that you are solely responsible for following laws related to your activity.\n" +
      "\n" +
      "When you use this Platform, you agree to Heymdal Studio charging a Service Fee, calculated on the basis of the Ride cost. The Service Fee is a percentage (4,5%) of the total amount meant to cover infrastructure cost, transaction fee to payment provider, and operational expenses for employment.\n" +
      "\n" +
      "As a Driver, you agree that no interest is entitled to the total amount of the Ride. You also agree that Heymdal Studio will send the cost contribution within 30 working days after the planned departure. This requires that all necessary information has been provided and that the Ride has been carried out as planned. Any payment that cannot be completed within 365 days because the necessary information is not attached in the app shall be deemed to belong to Heymdal Studio.\n" +
      "\n" +
      "Attempts to avoid using Heymdal Studio payment system, including, but not limited to, avoiding paying the Service Fee, are prohibited. Heymdal Studio reserves the right at any time and without notice to terminate Accounts that do not comply with these terms and conditions.",
    terms_point_8_title: "Maintenance and support",
    terms_point_8_paragraph_1:
      "Heymdal Studio is solely responsible for providing any maintenance and support services for this Application. You may reach the Licensor at the email listed in the App Store or Play Store Overview for this Licensed Application.",
    terms_point_8_paragraph_2:
      "Heymdal Studio and the User acknowledge that the Licensor has no obligation to furnish any maintenance and support services with respect to the Licensed Application.",
    terms_point_9_title: "Liability",
    terms_point_9_paragraph_1:
      "Heymdal Studio takes no accountability or responsibility for any damages (financial or non-financial) caused due to a breach of duties according to Section 2 of this License Agreement",
    terms_point_9_paragraph_2:
      "Heymdal Studio takes no accountability for injuries or situations resulting in death caused due to utilizing the Platform. ",
    terms_point_9_paragraph_3:
      "Heymdal Studio takes no accountability for financial damages as a result of utilizing the Platform.",
    terms_point_9_paragraph_4:
      "You (User) is solely accountable for events that result in a breach of Norwegian Law",
    terms_point_9_paragraph_5:
      "You as a Driver/Passenger and not Heymdal Studio are liable in events that lead to dangerous situations resulting in injury or death, as a consequence of incorrect information",
    terms_point_9_paragraph_6:
      "You as a Driver are solely accountable for following the Road Traffic Act and recommendations from governmental agencies",
    terms_point_10_title: "Warranty",
    terms_point_10_paragraph_1:
      "Heymdal Studio warrants that the Application is free of spyware, trojan horses, viruses, or any other malware at the time of Your download. Heymdal Studio warrants that the Application works as described in the user documentation",
    terms_point_10_paragraph_2:
      "No warranty is provided for the Application that is not executable on the device, that has been unauthorizedly modified, handled inappropriately or culpably, combined or installed with inappropriate hardware or software, used with inappropriate accessories, regardless if by Yourself or by third parties, or if there are any other reasons outside Heymdal Studio’s sphere of influence that affect the executability of the Application.",
    terms_point_10_paragraph_3:
      "You are required to inspect the Application immediately after installing it and notify Heymdal Studio about issues discovered without delay by contact information provided in the Section Contact. The defect report will be taken into consideration and further investigated if it has been emailed within a period of sixty (60) days after discovery.",
    terms_point_10_paragraph_4:
      "If we confirm that the Application is defective, Heymdal Studio reserves a choice to remedy the situation through solving the defect.",
    terms_point_11_title: "Termination",
    terms_point_11_subtitle:
      "The license is valid until terminated by Heymdal Studio or by You. Your rights under this license will terminate automatically and without notice from HEYMDAL STUDIO AS if You fail to adhere to any term(s) of this license. Upon license termination, You shall stop all use of the Application, and destroy all copies, full or partial, of the Application.",
    terms_point_12_title: "Third-party content",
    terms_point_12_subtitle:
      "The service may contain links to third-party content. Your use of links to third-party content is at your own risk, and you should do whatever research you believe is necessary before proceeding.",
    terms_point_13_title: "Personal data",
    terms_point_13_subtitle:
      "Heymdal Studio is the data processor for the user's personal data and follows current guidelines from the Norwegian Data Protection Authority for storage and processing. More information can be found in our privacy policy.",
    terms_point_14_title: "Conflict resolution",
    terms_point_14_subtitle:
      "Complaints about the application Trips must be directed to Heymdal Studio within a reasonable time. We strive to resolve all disputes amicably. If this is not successful, the user can contact the Norwegian Consumer Protection Authority for further mediation.",
    terms_point_15_title: "Applicable Law",
    terms_point_15_subtitle:
      "Claims arising as a result of, or linked to, these terms or the Service, will be subject to the laws of Norway. All disputes and lawsuits related to these terms and the Services will be handled exclusively in the Norwegian legal system, with the Oslo district court as the main seat.",
    terms_point_16_title: "Contact",
    terms_point_16_subtitle: `HEYMDAL STUDIO AS
E-mail: heymdal.app@gmail.com`,
  },
  norwegian: {
    pageNotFound: "Siden eksisterer ikke",
    languageName: "Norsk",
    menu: "Meny",
    home: "Hjem",
    about: "Om oss",
    contact: "Kontakt",
    forApple: "For Apple",
    forAndroid: "For Android",
    features: "Funksjoner",
    featuresSummaryDescription1:
      "Here is a summary of the amazing things you can do with the app",
    downloadSummaryDescription1: "Last ned og finn din første tur.",
    downloadSummaryDescription2: "Spar tid, penger og miljøet.",
    mainTitleDescription1:
      "Motta kompensasjon som sjåfør eller bli med på en tur til en kostnad lavere enn offentlig transport.",
    mainTitleDescription2:
      "Raskere, billigere og mer fleksibelt!",
    share: "Del",
    a: "en",
    ride: "tur",
    to: "til",
    work: "jobben",
    university: "campus",
    theCabin: "hytta",
    theBeach: "stranda",
    hometown: "hjembyen",
    howItWorks: "Slik fungerer det",
    messageSent: "Melding sendt",
    contactInfo: "Kontaktinfo",
    name: "Navn",
    email: "E-post",
    message: "Melding",
    send: "Send",
    contactInfoDescription1:
      "For kundeservice eller forretningsrelaterte henvendelser, vennligst kontakt oss ved å bruke skjemaet nedenfor.",
    howItWorksForPassengersTitle1:
      "Finn en sjåfør som reiser i samme retning",
    howItWorksForPassengersTitle2: "Møt til avtalt tid, sted og start turen",
    howItWorksForPassengersTitle3: "Fortell om din opplevelse",
    howItWorksForPassengersSubtitle1:
      "Bruk vår plattform til å finne en sjåfør som skal i samme retning. Bestilling og betaling gjøres via appen.",
    howItWorksForPassengersSubtitle2:
      "Start din reise. Snakk, lytt til musikk eller nyt turen i stillhet. Valget er ditt.",
    howItWorksForPassengersSubtitle3:
      "Etter turen kan du gi en vurdering av reisen som andre brukere av plattformen kan se.",
    howItWorksForDriversTitle1:
      "Tilby en tur og få dekket deler av dine reisekostnader",
    howItWorksForDriversTitle2: "Du har kontrollen",
    howItWorksForDriversTitle3: "Plukk opp passasjerer på avtalt sted og tid, og start turen",
    howItWorksForDriversTitle4: "Vurder dine passasjerer",
    howItWorksForDriversSubtitle1:
      "Med samkjøring kan du dekke deler av reisekostnadene dine, og bidra til mer miljøvennlig transport. Videre gir du folk en alternativ transportmåte med økt fleksibilitet og redusert pris.",
    howItWorksForDriversSubtitle2:
      "Opprett din tur og velg en pris basert på vår algoritme. Du kontrollerer reglene for reisen og de som får være med",
    howItWorksForDriversSubtitle3:
      "Si hei, pakk bilen og start turen. Snakk, lytt til musikk eller nyt stillheten. Ingen to turer er like, men alle er gode.",
    howItWorksForDriversSubtitle4:
      "Etter turen kan du gi en vurdering av passasjerene som andre brukere av plattformen kan se.",
    forPassengers: "For passasjerer",
    forDrivers: "For sjåfører",
    anyQuestions: "Har du spørsmål?",
    showMore: "Vis mer",
    privacy: "Personvern",
    privacyTitle1: "Personvernerklæring",
    privacySubtitle1:
      "Denne personvernerklæringen gjelder alle forretningsprosesser i Heymdal Studio AS, heretter benevnt som behandlingsansvarlig.\n" +
      "Personvernerklæringen gir informasjon om databehandling utført av Behandlingsansvarlig og bestemmer formålet og virkemidlene for behandlingen, samt databehandling vi gjør på vegne av våre kunder basert på deres instruksjoner.\n" +
      "\n" +
      "Behandling av personlige data er nødvendig for at vi skal kunne ivareta våre kontraktsforpliktelser og betjene deg som kunde. Personlige data kan også benyttes til følgende formål: Betalingstransaksjon, kontakt med kundeservice, markedsføring og bokføring.\n" +
      "\n" +
      "Ved markedsføring på e-post/sms innhentes et eksplisitt samtykke på forhånd.\n" +
      "\n" +
      "Ved å gi oss dine personlige opplysninger, godtar du i praksis vilkår som er beskrevet i denne personvernerklæringen. Om du har innsigelser med hensyn til vår behandling av personvern har du også anledning til å klage til Datatilsynet.\n",
    privacy_point_1_title: "Hvilke personopplysninger behandles",
    privacy_point_2_title: "Hva brukes personopplysningene til?",
    privacy_point_3_title: "Informasjonskapsler og analyseverktøy",
    privacy_point_4_title: "Utlevering av personopplysninger",
    privacy_point_5_title: "Lenker til tredjeparter",
    privacy_point_6_title: "Hvordan beskytter vi din personlige informasjon?",
    privacy_point_7_title: "Dine rettigheter",
    privacy_point_8_title: "Endringer i personvernerklæringen",
    privacy_point_1_subtitle:
      "For å kunne benytte applikasjonen må du opprette en brukerkonto, noe som fasiliteres gjennom Vipps. Dette gir Heymdal Studio tilgang på følgende opplysninger:\n" +
      "\n" +
      "\t · \t Fullt navn: Verifikasjon av identitet\n" +
      "\t · \t Bursdagsdato: Benyttes for å verifisere alder til sjåfør\n" +
      "\t · \t Epost: Benyttes til kommunikasjon mellom Heymdal Studio og bruker\n" +
      "\t · \t Telefonnummer: Verifikasjon av identitet\n" +
      "\n" +
      "I tillegg til dine personopplysninger knyttet til din brukerkonto, lagrer og behandler vi data om tilknyttet bankkonto (Vipps), for å kunne fasilitere betalingstransaksjon. Dette kan endres under “Min profil” om ønskelig.\n" +
      "Innhold som fremkommer gjennom brukeranmeldelser og beskrivelser vil også bli lagret.",
    privacy_point_2_subtitle:
      "Personopplysningene brukes primært til verifikasjon av brukerens identitet og alder, samt til å kunne prosessere transaksjonen mellom passasjer og sjåfør på en sømløs måte.\n" +
      "\n" +
      "Videre samler og behandler vi informasjon når du bruker tjenestene våre for å analysere bruksmønstre, forbedre tjenestene våre og for å forhindre og oppdage misbruk. Med ditt samtykke vil vi også bruke denne informasjonen til å kunne tilpasse innhold og tilby deg målrettet informasjon, tilbud og annonsering basert på atferd og antakelser om preferanser.\n" +
      "\n" +
      "Vi kan bruke din personlige informasjon til følgende formål:\n" +
      "\n" +
      "\t · \t For å opprette og administrere en brukerkonto knyttet til tjenesten\n" +
      "\t · \t For å behandle henvendelser via våre digitale tjenester\n" +
      "\t · \t For å kunne kommunisere med deg i forbindelse med din bruk av applikasjonen\n" +
      "\t · \t For å analysere informasjonen slik at vi, med ditt samtykke, kan sende deg relevante tilbud og informasjon.\n" +
      "\t · \t For å sende deg spørreundersøkelser som gir deg mulighet til å påvirke utviklingen av applikasjonen\n" +
      "\t · \t For å teste og forbedre systemene vi bruker for å tilby tjenester\n" +
      "\t · \t For å forhindre misbruk eller misbruk av våre tjenester\n" +
      "\t · \t For å verifisere brukerens identitet og alder\n" +
      "\t · \t For å kunne formidle en transaksjon\n" +
      "\n" +
      "Vi vil bare beholde opplysningene dine så lenge det er nødvendig for å oppfylle formålet med behandlingen, eller så lenge loven krever at de oppbevares. Etter dette vil din personlige informasjon bli slettet eller anonymisert. Anonym informasjon kan ikke spores tilbake til deg.",
    privacy_point_3_subtitle:
      "Vi bruker ulike analyseverktøy og lagrer informasjonskapsler fra applikasjonen for å måle brukertrafikk, for å lagre historikk, og for å forbedre og forenkle din bruk av våre tjenester.\n" +
      "\n" +
      "Informasjonskapsler er små tekstfiler som lagres i nettleseren på din datamaskin eller mobilenhet. Vi bruker ikke informasjonskapsler til å lagre personlig informasjon. Ved å benytte applikasjonen godtar du at vi bruker informasjonskapsler, slik at vi kan gi deg en bedre brukeropplevelse.\n" +
      "\n" +
      "Vi bruker Google Analytics til å registrere og analysere anonym statistikk for besøk på Trips sin hjemmeside. For hver side du besøker lagres informasjon som dato og klokkeslett, hvilken side du kom fra, hvilken side du gikk til, hvilken nettleser du bruker osv.",
    privacy_point_4_subtitle:
      "Vi selger ikke personlig informasjon for markedsføringsformål til andre selskaper. Vi kan bare dele din personlige informasjon i følgende tilfeller:\n" +
      "\n" +
      "\t · \t våre partnere, når du har godtatt det,\n" +
      "\t · \t med våre tredjeparts tjenesteleverandører, som utfører oppgaver eller arbeider på våre vegne og i samsvar med våre instruksjoner som databehandlere, for formålene angitt i denne personvernerklæringen,\n" +
      "\t · \t for å beskytte og forsvare tjenesten (inkludert håndheving av gjeldende bruksvilkår), \n" +
      "\t · \t til offentlige myndigheter eller etater ved forespørsel om innsyn.",
    privacy_point_5_subtitle:
      "Denne personvernerklæringen gjelder kun Heymdal Studio sin bruk, innsamling og utlevering av personopplysninger. Tjenesten kan inneholde lenker til andre nettsteder som ikke er under vår kontroll. Heymdal Studio er ikke ansvarlig for innholdet på disse nettsidene og er ikke ansvarlig for eventuell behandling av personopplysninger på disse nettsidene.",
    privacy_point_6_subtitle:
      "Vi har iverksatt tekniske og organisatoriske tiltak for å beskytte din personlige informasjon slik at den ikke forsvinner, manipuleres eller at uvedkommende får tilgang til den. Vi tilpasser fortløpende sikkerhetstiltakene til den teknologiske utviklingen.\n" +
      "\n" +
      "Personopplysninger som er registrert eller presentert i applikasjonen sendes i kryptert form. Dette betyr at informasjonen går gjennom en sikker forbindelse og at dine personopplysninger ikke kan leses av eksterne parter. For å sikre god beskyttelse av personopplysninger benytter vi kryptering i kombinasjon med “salting”. Det er samtidig bare vår server som har tilgang til krypteringsnøklene.",
    privacy_point_7_subtitle:
      "Du har rett til å be om informasjon om personopplysningene vi har om deg når som helst. Dersom opplysningene er uriktige, ufullstendige eller irrelevante, kan du be om at opplysningene blir rettet eller fjernet. Henvendelser om innsyn, retting og sletting vil bli besvart fortløpende og senest innen 30 dager. Vi kan ikke fjerne opplysningene dine dersom det er lovpålagte krav til at disse skal lagres, som f.eks. regnskapsregler eller andre legitime grunner til å oppbevare opplysningene.",
    privacy_point_8_subtitle:
      "Heymdal Studio AS kan revidere denne personvernerklæringen når behandlingen av personopplysninger endres eller når revisjon er nødvendig på grunn av endringer i gjeldende lovverk. Når vi gjør endringer i denne erklæringen, vil vi endre revisjonsdatoen, og den endrede personvernerklæringen trer i kraft fra siste revisjonsdato. Vi vil gjøre større endringer godt synlige, men anbefaler at du leser personvernerklæringen regelmessig når du bruker applikasjonen.",
    showLess: "Vis mindre",
    aboutUsDescription1:
      "Trips er en plattform bygget rundt visjonen om å gjøre transport mer fleksibel, rimelig og miljøvennlig. Sammenlignet med andre tjenester, som Uber og Bolt, sentrerer Trips seg rundt prinsippet om samkjøring og dekning av selvkost. Kostnaden for en reise beregnes ut fra reiseavstand og statens standard satser." +
      "\n" +
      "\n" +
      "Bak plattformen står et lite team med tverrfunksjonell kompetanse fra norsk tech-industri. Vårt mål er å bygge et produkt som er enkelt å bruke, praktisk og genuint nyttig. Plattformen er utviklet med det formål å løse problemer i det moderne samfunn. Videre er plattformavgiften fra hver tur kun ment å dekke driftsutgifter og ikke resultere i fortjeneste. Vi håper du kan bli med oss på reisen vår!",
    currentRides: "Aktive bilturer",
    users: "Brukere",
    ridesShared: "Bilturer delt",
    kgOfCo2Saved: "kg C02 spart",
    terms: "Vilkår",
    termsTitle1: "Tjenestevilkår",
    lastUpdatedDateTerms1: "Sist oppdatert 23. mai, 2023",
    lastUpdatedDatePrivacy1: "Sist oppdatert 15. juni, 2023",
    termsSubtitle1:
      "Ved å bruke tjenestene på denne nettsiden godtar du følgende vilkår og betingelser.",
    terms_point_1_title: "Introduksjon",
    terms_point_1_subtitle:
      "Ved å bruke applikasjonen Trips aksepterer brukeren å være bundet av vilkårene og bestemmelsene i avtalen inngått mellom brukeren og Heymdal Studio AS, et selskap registrert etter norsk lov, med organisasjonsnummer 928958515. Dette inkluderer å være underlagt og overholde alle retningslinjer og regler som gjelder for tjenesten. Hvis du ikke godtar å være bundet av denne avtalen, er all bruk av applikasjonen Trips forbudt. Enhver deltakelse i eller bruk av Trips applikasjonen vil derfor utgjøre aksept av denne avtalen.\n" +
      "\n" +
      "Trips er en digital platform som gjør det mulig for brukere å komme i kontakt, planlegge og dele reisekostnader for samkjøring. Tjenesten er ikke en kommersiell transportløsning. Brukere inngår direkte avtaler med hverandre, og Heymdal Studio er ikke part i disse.\n" +
      "Sjåfører og passasjerer kan ikke sende eller frakte gjenstander som er ulovlig å transportere etter norsk lov.\n" +
      "\n" +
      "Som bruker av denne applikasjonen godkjenner du kommunikasjon fra Heymdal Studio.\n",

    terms_point_2_title: "Omfang av tjenesten",
    terms_point_2_paragraph_1:
      "Heymdal Studio AS er eneeier av alle immaterielle rettigheter knyttet til applikasjonen Trips, inkludert, men ikke begrenset til kildekode, logoer, videoer, lyder, design, bilder, tekst og data. Med forbehold om overholdelse av disse vilkårene, gir Heymdal Studio AS deg en ikke-eksklusiv, ikke-overførbar og personlig lisens til å bruke plattformen og dens tjenester.",
    terms_point_2_paragraph_2:
      "Du samtykker i at innhold og data som du gir på plattformen, kan brukes og oversettes av Heymdal Studio AS.",
    terms_point_2_paragraph_3:
      "Du har ikke lov til å dele eller gjøre applikasjonen tilgjengelig for tredjeparter, selge, leie ut, låne ut eller på annen måte distribuere applikasjonen.",
    terms_point_2_paragraph_4:
      "Du har ikke lov til å reversere, oversette, demontere, integrere, dekompilere, fjerne, modifisere, kombinere, opprette avledede verk eller oppdateringer av, tilpasse eller forsøke å utlede kildekoden til applikasjonen eller deler av den.",
    terms_point_2_paragraph_5:
      "Heymdal Studio forbeholder seg retten til å endre vilkårene for lisensiering.",
    terms_point_2_paragraph_6:
      "Ved å registrere deg på plattformen erkjenner og aksepterer du at Heymdal Studio kan bruke og lagre noen av dine personlige data. Heymdal Studio kan kontakte deg med nyheter og oppdateringer. Mer informasjon finnes i vår personvernerklæring.",
    terms_point_3_title: "Registrering",
    terms_point_3_subtitle:
      "For å opprette en konto på plattformen må du ha lest og akseptert avtalevilkårene og personvernerklæringen vår.\n" +
      "Ved å registrere deg på plattformen garanterer du at du er 18 år eller eldre. Applikasjonen bruker Vipps for å legge til rette for en sikker registreringsprosess. Alle brukere må derfor ha en Vipps-konto for å kunne benytte plattformen.\n" +
      "\n" +
      "Ved registrering samtykker du i å oppgi nøyaktig informasjon om profilen din til enhver tid. Heymdal Studio er ikke ansvarlig for at brukerne oppgir nøyaktig og korrekt informasjon om identitet eller annen informasjon som legges ut på denne plattformen. Heymdal Studio forbeholder seg retten til å blokkere en konto dersom brukeren ikke oppgir nøyaktig og sannferdig informasjon.",
    terms_point_4_title: "Bruk",
    terms_point_4_paragraph_1:
      "Bruk for sjåfører\n" +
      "Når du legger ut en tur som sjåfør på plattformen, er du ansvarlig for å sikre at alt som gjelder turen, er nøyaktig og sannferdig. Du er forpliktet til å gjennomføre reisen slik den er angitt i beskrivelsen, inkludert å vente minst 10 minutter på passasjer(ene) ved planlagt hentepunkt. Heymdal Studio kan fjerne turen din uten varsel hvis den ikke oppfyller retningslinjene beskrevet i vilkår og betingelser.\n" +
      "\n" +
      "Når du legger ut en tur, samtykker du i å følge Norges lover og retningslinjer. Dette inkluderer også at du som sjåfør:\n" +
      "\n" +
      "\t · \t Ikke transporterer ulovlige varer;\n" +
      "\t · \t Har gyldig førerkort;\n" +
      "\t · \t Er i god helse og ikke under påvirkning av medisiner, alkohol eller andre russtoffer;\n" +
      "\t · \t Har en bil som er i lovlig stand og forsikret;\n" +
      "\t · \t Enten eier eller har tillatelse til å låne bilen du bruker;\n" +
      "\t · \t Oppbevarer bagasje og andre gjenstander på en måte som ikke påvirker passasjerens komfort eller sikkerhet;",
    terms_point_4_paragraph_2:
      "Bruk for passasjerer\n" +
      "Du samtykker i å gi sannferdig informasjon til andre brukere. Du er ansvarlig for å delta i og fullføre reisen slik den er angitt i turen, inkludert å vente minst 10 minutter etter avtalt ankomsttidspunkt for sjåføren på det planlagte hentepunktet. Passasjerer er ansvarlige for ikke å bruke tjenesten til ulovlige aktiviteter eller sende forbudte varer. \n" +
      "\n" +
      "Denne tjenesten er bare en plattform der brukere kan inngå direkte avtaler med hverandre. Heymdal Studio er ikke en part i avtalene mellom brukere. Heymdal Studio er heller ikke ansvarlig for hva som blir transportert eller hvordan det blir gjort, dette er en sak mellom brukere.\n" +
      "\n" +
      "All ulovlig bruk av denne tjenesten må rapporteres.",
    terms_point_5_title: "Brukeranmeldelser",
    terms_point_5_subtitle:
      "Heymdal Studio oppfordrer brukere til å legge igjen en brukeranmeldelse etter å ha fullført en tur. Brukeranmeldelsen skal være saklig og ikke basert på uriktige opplysninger. All banning, henvisninger til rase, trusler, vulgært språk, personangrep og annet språk som omfattes av lovgivningen i Norge er strengt forbudt og kan føre til blokkering fra Plattformen. Du samtykker i at Heymdal Studio forbeholder seg retten til å slette eller ikke publisere anmeldelser som ikke er i samsvar med disse vilkårene, eller av andre grunner anses upassende basert på omdømme, lovlighet eller andre berettigede grunner.\n" +
      "Heymdal Studio forbeholder seg retten til å avslutte enhver konto, basert på gjentatte upassende brukeranmeldelser.",
    terms_point_6_title: "Kansellering av tur",
    terms_point_6_subtitle:
      "Etter at forespørselen er bekreftet, kan både sjåføren og passasjeren avbestille/kansellere turen.",
    terms_point_6_paragraph_1:
      "Hvis sjåføren kansellerer turen eller ikke møter opp på avtalt hentepunkt innenfor rimelig tid, vil passasjeren få refundert det totale beløpet betalt, inkludert serviceavgiften.\n\nHvis passasjeren avbestiller turen, avhenger refusjonen av når avbestillingen blir gjort.",
    terms_point_6_paragraph_2:
      "Hvis avbestillingen gjøres mer enn 24 timer før planlagt avreise, vil passasjeren få refundert det totale beløpet, ekskludert serviceavgiften.",
    terms_point_6_paragraph_3:
      "Hvis avbestillingen gjøres 24 timer eller mindre før avreise, blir det totale beløpet betalt delt 50/50 mellom sjåfør og passasjer, etter at serviceavgiften er trukket fra det totale beløpet.",
    terms_point_6_paragraph_4:
      "Hvis avbestillingen gjøres 4 timer eller mindre før avreise, vil det ikke bli gitt noen refusjon. Sjåføren mottar det totale beløpet, ekskludert serviceavgiften.",
    terms_point_6_paragraph_5:
      "Hvis bestillingen derimot ble gjort i løpet av de 24 timene før avreise, men avbestilles mindre enn 30 minutter etter bestillingen, gjelder de samme reglene som for avbestillinger før 24 timer.",
    terms_point_6_paragraph_6:
      "Hvis passasjeren avbestiller etter planlagt avgangstid, vil det ikke bli gitt refusjon. Sjåføren mottar det totale beløpet betalt, ekskludert serviceavgiften. Det samme gjelder hvis passasjeren ikke møter opp på møtepunktet 10 minutter etter avtalt tid.",
    terms_point_7_title: "Transaksjon og serviceavgift",
    terms_point_7_subtitle:
      "Applikasjonen Trips bruker Vipps som betalingsbehandler. For å kunne gjøre og/eller motta betalinger på denne plattformen, må du akseptere å dele bankdetaljene dine med Heymdal Studio.\n\nDet er strengt forbudt å tjene penger på transport av passasjerer via plattformen. Profitt oppstår hvis du som bruker ber om en pris som er høyere enn selvkost. Heymdal Studio foreslår en pris for turen basert på avstand og statlige retningslinjer for kjøregodtgjørelse. Du erkjenner og aksepterer at du selv er ansvarlig for å følge lover og regler innenfor persontransport.\n\nNår du bruker denne plattformen, godtar du at Heymdal Studio belaster en serviceavgift, beregnet ut fra turens kostnad. Serviceavgiften utgjør en prosentandel (4,5%) av det totale beløpet og er ment å dekke infrastrukturkostnader, transaksjonsgebyr til betalingsleverandør og driftsutgifter for ansettelse.\n\nSom sjåfør godtar du at ingen rente tilkommer det totale beløpet for turen. Du godtar også at Heymdal Studio vil sende kostnadsbidraget innen 30 arbeidsdager etter planlagt avgang. Dette forutsetter at all nødvendig informasjon er blitt gitt og at turen er gjennomført som planlagt. Enhver betaling som ikke kan fullføres innen 365 dager fordi nødvendig informasjon ikke er vedlagt i appen, skal anses å tilhøre Heymdal Studio.\n\nForsøk på å unngå å bruke Heymdal Studio sitt betalingssystem, inkludert, men ikke begrenset til, å unngå å betale serviceavgiften, er forbudt. Heymdal Studio forbeholder seg retten til enhver tid og uten varsel å avslutte kontoer som ikke følger disse vilkårene og betingelsene.",
    terms_point_8_title: "Vedlikehold og support",
    terms_point_8_paragraph_1:
      "Heymdal Studio er alene ansvarlig for å tilby vedlikehold og supporttjenester for denne applikasjonen. Du kan kontakte Heymdal Studio på e-postadressen oppført i App Store eller Play Store-oversikten.",
    terms_point_8_paragraph_2:
      "Heymdal Studio og brukeren erkjenner at lisensgiveren ikke har noen forpliktelse til å gi vedlikeholds- og supporttjenester med hensyn til den lisensierte applikasjonen.",
    terms_point_9_title: "Ansvar",
    terms_point_9_paragraph_1:
      "Heymdal Studio tar ingen ansvar for eventuelle skader (økonomiske eller ikke-økonomiske) som oppstår som følge av brudd på plikter i henhold til disse vilkårene.",
    terms_point_9_paragraph_2:
      "Heymdal Studio tar ingen ansvar for skader eller situasjoner som resulterer i dødsfall som følge av bruk av plattformen.",
    terms_point_9_paragraph_3:
      "Heymdal Studio tar ingen ansvar for økonomiske skader som følge av bruk av plattformen.",
    terms_point_9_paragraph_4:
      "Du (bruker) er utelukkende ansvarlig for hendelser som resulterer i brudd på norsk lov.",
    terms_point_9_paragraph_5:
      "Du som sjåfør/passasjer og ikke Heymdal Studio er ansvarlig for hendelser som fører til farlige situasjoner som resulterer i skade eller død.",
    terms_point_9_paragraph_6:
      "Du som sjåfør er utelukkende ansvarlig for å følge vegtrafikkloven og anbefalinger fra statlige etater.",
    terms_point_10_title: "Garanti",
    terms_point_10_paragraph_1:
      "Heymdal Studio garanterer at applikasjonen er fri for spionprogrammer, trojanske hester, virus eller annen skadelig programvare ved tidspunktet for nedlastingen.",
    terms_point_10_paragraph_2:
      "Ingen garanti gis for applikasjonen som ikke kan kjøres på enheten, som har blitt uautorisert modifisert, håndtert på en upassende eller skyldig måte, kombinert eller installert med upassende maskinvare eller programvare, brukt med upassende tilbehør, uansett om det er gjort av deg selv eller av tredjeparter, eller hvis det er andre årsaker utenfor Heymdal Studio sin innflytelsessfære som påvirker kjørbarheten til applikasjonen.",
    terms_point_10_paragraph_3:
      "Du er pålagt å undersøke applikasjonen umiddelbart etter installasjonen og uten opphold varsle Heymdal Studio om oppdagede problemer via kontaktinformasjonen som er oppgitt i avsnittet Kontakt. Feilrapporten vil bli tatt i betraktning og videre undersøkt hvis den er sendt per e-post innen en periode på seksti (60) dager etter oppdagelsen.",
    terms_point_10_paragraph_4:
      "Hvis vi bekrefter at applikasjonen er defekt, forbeholder Heymdal Studio seg retten til å utbedre situasjonen ved å løse feilen.",
    terms_point_11_title: "Oppsigelse",
    terms_point_11_subtitle:
      "Lisensen er gyldig til den blir avsluttet av Heymdal Studio eller av deg. Dine rettigheter i henhold til denne lisensen vil automatisk opphøre uten varsel fra HEYMDAL STUDIO AS hvis du ikke overholder noen vilkår i denne lisensen. Ved lisensens oppsigelse skal du slutte å bruke applikasjonen og ødelegge alle kopier, hele eller delvise, av applikasjonen.",
    terms_point_12_title: "Innhold fra tredjeparter",
    terms_point_12_subtitle:
      "Tjenesten kan inneholde lenker til innhold fra tredjeparter. Din bruk av lenker til innhold fra tredjeparter skjer på egen risiko, og du bør utføre nødvendig undersøkelse før du fortsetter.",
    terms_point_13_title: "Personopplysninger",
    terms_point_13_subtitle:
      "Heymdal Studio er databehandler for brukerens personvernsopplysninger og følger gjeldende retningslinjer fra Datatilsynet for lagring og prosessering. Mer informasjon finnes i vår personvernerklæring.",
    terms_point_14_title: "Konflikthåndtering",
    terms_point_14_subtitle:
      "Klager til applikasjonen Trips skal rettest til Heymdal Studio innen rimelig tid. Vi etterstreber å løse alle tvister i minnelighet. Dersom dette ikke lykkes, kan brukeren kontakte Forbrukertilsynet for mekling.",
    terms_point_15_title: "Lovvalg",
    terms_point_15_subtitle:
      "Krav som oppstår som følge av eller knyttet til disse vilkårene eller tjenesten, vil være underlagt norsk lov. Alle tvister og søksmål knyttet til disse vilkårene og tjenestene vil bli behandlet utelukkende i det norske rettssystemet, med Oslo tingrett som hovedsete.",
    terms_point_16_title: "Kontakt",
    terms_point_16_subtitle: "HEYMDAL STUDIO AS\nE-post: heymdal.app@gmail.com",
  },
};
