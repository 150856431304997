import AboutSection from "components/pages/about/components/about-section";
import * as React from "react";

const AboutPage = () => {
  return (
    <div>
      <AboutSection/>
    </div>
  );
};

export default AboutPage;
