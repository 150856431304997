import BurgerButton from "components/nav-bar/components/burger-button";
import DropdownMenu from "components/nav-bar/components/dropdown-menu";
import NavBarItems from "components/nav-bar/components/nav-bar-items";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import { images } from "utils/images";
import { paths } from "utils/routes";

const NavBar = () => {
  let location = useLocation();
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const hideNavBar = searchParams.get("hideNavBar") === "true";

  useEffect(() => {
    setNavbarOpen(false);
  }, [location]);

  return hideNavBar ? null : (
    <div className="relative bg-surface px-5 text-center text-onSurface">
      <div className=" mx-auto flex max-w-6xl items-center justify-between py-5 md:py-3">
        <div className="flex w-full justify-center ">
          <div className="flex w-full justify-between md:w-auto md:justify-start">
            <NavLink
              to={paths.home}
              className="my-auto inline-block w-32 md:w-48"
            >
              <img src={images.appLogo} alt="App logo" />
            </NavLink>
            <BurgerButton onTap={() => setNavbarOpen(!navbarOpen)} />
          </div>
          <div className="ml-auto hidden items-center space-x-5 md:flex">
            <NavBarItems maxItems={3} />
            {/*<SocialMediaLinks />*/}
            {/*<ChangeLanguageDropdown />*/}
          </div>
        </div>
        <DropdownMenu
          className={`${
            navbarOpen ? "" : "hidden"
          } absolute bottom-0 left-0 z-20 w-full translate-y-full transform shadow-xl md:hidden`}
        />
      </div>
    </div>
  );
};
export default NavBar;
