import clsx from "clsx";
import NavBarLinkItem from "components/nav-bar/components/nav-bar-link-item";
import { paths } from "utils/routes";
import React from "react";
import { useIntl } from "react-intl";

export const appNavigationLinks = [
  {
    intlId: "home",
    path: paths.home,
  },
  {
    intlId: "about",
    path: paths.about,
  },
  {
    intlId: "contact",
    path: paths.contact,
  },
  {
    intlId: "terms",
    path: paths.terms,
  },
  {
    intlId: "privacy",
    path: paths.privacy,
  },
];

type Props = {
  className?: string;
  maxItems?: number;
};

const NavBarItems = ({ className, maxItems }: Props) => {
  const intl = useIntl();

  return (
    <div
      className={clsx(className, "flex items-center")}
      id="example-navbar-danger"
    >
      <div className="flex w-full flex-col md:ml-auto md:w-auto md:flex-row">
        {appNavigationLinks.map((link, index) =>
          maxItems !== null && index >= maxItems! ? null : (
            <NavBarLinkItem
              title={intl.formatMessage({ id: link.intlId })}
              to={link.path}
              key={link.intlId}
            />
          )
        )}
      </div>
    </div>
  );
};
export default NavBarItems;
