import React from "react";
import PrivacyTitle from "components/pages/privacy/components/privacy-title";
import PrivacyContent from "components/pages/privacy/components/privacy-content";
import { useSearchParams } from "react-router-dom";

const PrivacySection = () => {
  const [searchParams] = useSearchParams();
  const focusedParagraphNumber = searchParams.get("focusedParagraphNumber");

  return (
    <div className="prose mx-auto w-full max-w-5xl px-5 py-6 lg:py-16">
      {!focusedParagraphNumber && <PrivacyTitle />}
      <PrivacyContent />
    </div>
  );
};
export default PrivacySection;
