import React from "react";

const TermsContent = () => {
  return (
    <div className={`mb-4 leading-5 `}>
      <span className="whitespace-break-spaces text-sm">
        {"1. The Application\n" +
          "Heymdal is an Application created to provide end-users with the opportunity to experience hidden gems in nature and locate new accommodations in nature. The Application is customized for iOS and Android mobile devices.\n" +
          "\n" +
          "2. Scope of License\n" +
          "2.1 You are given a non-transferable, non-exclusive, non-sublicensable license to install and use the Licenced Application on any Devices that You (End-user) own or control and as permitted by the Usage Rules, with the exception that such Licensed Application may be accessed and used by other accounts associated with You (End User, The Purchaser).\n" +
          "\n" +
          "2.2 This license will also govern any updates of the Licensed Application provided by Licensor that replace, repair, and/or supplement the first Licensed Application, unless a separate license is provided for such update, in which case the terms of that new license will govern.\n" +
          "\n" +
          "2.3 You may not share or make the Licensed Application available to third parties, sell, rent, lend or lease or otherwise redistribute the Licensed Application.\n" +
          "\n" +
          "2.4 You may not reverse engineer, translate, disassemble, integrate, decompile, remove, modify, combine, create derivative works or updates of, adapt, or attempt to derive the source code of the Licensed Application, or any part thereof.\n" +
          "\n" +
          "2.5 You may not copy or alter the Licensed Application or portions thereof. You may create and store copies only on devices that You own or control for backup keeping under the terms of this license, the Usage Rules, and any other terms and conditions that apply to the device or software used. You may not remove any intellectual property notices. You acknowledge that no unauthorized third parties may gain access to these copies at any time.\n" +
          "\n" +
          "2.6 Licensor reserves the right to modify the terms and conditions of licensing.\n" +
          "\n" +
          "2.7 All content in the Licenced Application is for your personal use only and You may not use it for commercial purposes. The use of automated methods or software to collect content from the Licensed Application for commercial use is prohibited.\n" +
          "\n" +
          "3. Technical Requirements\n" +
          "3.1 The application requires a firmware version 1.0.0 or higher. Licensor recommends using the latest version of the firmware.\n" +
          "\n" +
          "3.2 The Licensor will attempt to keep the Licensed Application updated so that it complies with modified/new versions, and new hardware. You are not granted rights to claim such an update.\n" +
          "\n" +
          "3.3 You acknowledge that it is Your responsibility to confirm and determine that the app end-user device on which You intend to use the Licensed Application satisfies the technical specifications mentioned above.\n" +
          "\n" +
          "3.4 Licensor reserves the right to modify the technical såecifications as it sees appropriate at any time.\n" +
          "\n" +
          "4. Maintenance and support\n" +
          "4.1 The Licensor is solely responsible for providing any maintenance and support services for this Licensed Application. You may reach the Licensor at the email listed in the App Store or Play Store Overview for this Licensed Application.\n" +
          "\n" +
          "4.2 HEYMDAL STUDIO AS and the End-User acknowledge that the Licensor has no obligation to furnish any maintenance and support services with respect to the Licensed Application.\n" +
          "\n" +
          "5. User-Generated contributions\n" +
          "The Licensed Application provides the end-user with the opportunity to contribute through marker creation (public), graphics (pictures) and reviews. Contributions can be viewed by other users of the Licensed Application, if selected by You. As such, any Contributions you transmit may be treated as non-confidential and non-proprietary. When you create and make public available any Contributions, you thereby represent and warrant that:\n" +
          "\n" +
          "1. The creation, distribution and public display of your contribution do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, or moral rights of any third-party.\n" +
          "\n" +
          "2. Your public contributions can be used by us, the Licensed Application, in any manner contemplated by the Licensed Application and the License Agreement.\n" +
          "\n" +
          "3. You have the written consent, release, and/or permission of each and every identified individual person in your contribution.\n" +
          "\n" +
          "4. Your contributions are not false, inaccurate, or misleading.\n" +
          "\n" +
          "5. Your contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, spam, or other forms of solicitation.\n" +
          "\n" +
          "6. Your contributions are not obscene, lewd, lascivious, filthy, violent, harassing or otherwise objectionable (as determined by us).\n" +
          "\n" +
          "7. Your contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.\n" +
          "\n" +
          "8. Your contribution to not violate any applicable law, regulation, or rule.\n" +
          "\n" +
          "9. Your contributions do not violate the privacy or publicity right of any third party.\n" +
          "\n" +
          "10. Your contributions do not include any offensice comments that are connected to race, national origin, sexual preference, or physical handicap.\n" +
          "\n" +
          "11. Your contribution do not otherwise violate, or link to material that violates, any provision of this License Agreement, or any applicable law or regulation\n" +
          "\n" +
          "Any use of the Licensed Application in violation of the foregoing violated this License Agreement and may result in, among other things, termination or suspension of your rights to use the Licensed Application. You as the contributor are legally liable and not HEYMDAL STUDIO AS if any infringement of the above mentioned points are breached.\n" +
          "\n" +
          "6. Contribution License\n" +
          "By posting your contribution public or making the contribution accessible through social network sharing, you automatically grant, and you represent and warrant that you have the right to grant, to us as an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, worldwide right, and license to host, use, copy, reproduce, disclose, sell, resell, publish, translate, and distribute such contributions (including, without limitation, your text and image) for any purpose. The use and distribution may occur in any format and through any channel. \n" +
          "\n" +
          "We do not assert any ownership over your contributions. You retain full ownership of all of your contributions and any intellectual property rights or other proprietary rights associated with your contributions. We are not liable for any statements or representations in your contributions provided by you in any area in the Licensed Application. You are solely responsible for your contributions to the Licensed Application and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your contributions.\n" +
          "\n" +
          "We have the right, in our sole and absolute discretion, 1) to edit, redact, or otherwise change any contributions; 2) to recategorize any contributions to place them in more appropriate categories or locations in the Licensed Application; and 3) to prescreen or delete any public contribution at any time and for any reason without notice. We have no obligation to monitor your contributions.\n" +
          "\n" +
          "7. Liability\n" +
          "7.1 Licensor takes no accountability or responsibility for any damages (financial or non-financial) caused due to a breach of duties according to Section 2 of this License Agreement\n" +
          "\n" +
          "7.2 Licensor takes no accountability for injuries or situations resulting in death caused due to utilizing the Application. \n" +
          "\n" +
          "7.3 Licensor takes no accountability for financial damages as a result of utilizing the Licensed Application.\n" +
          "\n" +
          "7.3 You (End-user) is solely accountable for events that result in a breach of Norwegian Law\n" +
          "\n" +
          "7.4 You as a contributor and not HEYMDAL STUDIO are liable in events that lead to dangerous situations resulting in injury or death, as a consequence of incorrect placement of markers or information pertained to the marker. \n" +
          "\n" +
          "7.5 You as a contributor and not HEYMDAL STUDIO are liable in events where the incorrect placement or information pertained to a marker results in violation of private or public property rights.\n" +
          "\n" +
          "8. Warranty\n" +
          "8.1 Licensor warrants that the Licensed Application is free of spyware, trojan horses, viruses, or any other malware at the time of Your download. Licensor warrants that the Licensed Application works as described in the user documentation\n" +
          "\n" +
          "8.2 No warranty is provided for the Licensed Application that is not executable on the device, that has been unauthorizedly modified, handled inappropriately or culpably, combined or installed with inappropriate hardware or software, used with inappropriate accessories, regardless if by Yourself or by third parties, or if there are any other reasons outside HEYMDAL STUDIO AS’s sphere of influence that affect the executability of the Licensed Application.\n" +
          "\n" +
          "8.3 You are required to inspect the Licensed Application immediately after installing it and notify HEYMDAL STUDIO AS about issues discovered without delay by contact information provided in the Section Contact. The defect report will be taken into consideration and further investigated if it has been emailed within a period of sixty (60) days after discovery.\n" +
          "\n" +
          "8.4 If we confirm that the Licensed Application is defective, HEYMDAL STUDIO AS reserves a choice to remedy the situation through solving the defect.\n" +
          "\n" +
          "9. Termination\n" +
          "The license is valid until terminated by HEYMDAL STUDIO AS or by You. Your rights under this license will terminate automatically and without notice from HEYMDAL STUDIO AS if You fail to adhere to any term(s) of this license. Upon license termination, You shall stop all use of the Licensed Application, and destroy all copies, full or partial, of the Licensed Application.\n" +
          "\n" +
          "10. Intellectual Property Rights\n" +
          "HEYMDAL STUDIO AS and the End-user acknowledge that, in the event of any third-party claim that the Licensed Application or the End-User’s possession and use of that Licensed Application infringe on the third party’s intellectual property rights, HEYMDAL STUDIO AS, will be solely responsible for the investigation, defense, settlement, and discharge or any such intellectual property infringement claims.\n" +
          "\n" +
          "11. Applicable Law\n" +
          "The License Agreement is governed by the laws of Norway.\n" +
          "\n" +
          "12. Contact\n" +
          "HEYMDAL STUDIO AS\n" +
          "\n" +
          "Sigrid Undsets Vei 7D\n" +
          "\n" +
          "0669 Oslo\n" +
          "\n" +
          "E-mail: heymdal.app@gmail.com"}
      </span>
    </div>
  );
};
export default TermsContent;
