import markerBathingPlaceImport from "assets/images/activities_bathing-place-150x150.png";
import markerFishingSpotImport from "assets/images/activities_fishing-spot-150x150.png";
import smartphoneTripleImport from "assets/images/alle-samlet-1024x840-new.png";
import smartphoneImport from "assets/images/filter-522x1024-new.png";
import burgerMenuIconImport from "assets/images/hamburger-menu-icon.webp";
import smartphone2Import from "assets/images/hele-norge-522x1024-new.png";
import appLogoImport from "assets/images/logo-with-text-300x45.png";
import appLogoSmallImport from "assets/images/logo.png";
import markerStarCarabinerImport from "assets/images/marker_star_carabiner.png";
import markerStarHammockImport from "assets/images/marker_star_hammock.png";
import markerStarHutImport from "assets/images/marker_star_hut.png";
import markerAttractionImport from "assets/images/on-the-road_attraction-150x150.png";
import markerChargingStationImport from "assets/images/on-the-road_charging-station-150x150.png";
import markerFoodStoreImport from "assets/images/on-the-road_foodstore-150x150.png";
import markerGasStationImport from "assets/images/on-the-road_gas-station-150x150.png";
import markerToiletImport from "assets/images/on-the-road_toilet-150x150.png";
import markerViewpointImport from "assets/images/on-the-road_viewpoint-150x150.png";
import parallaxImageImport1 from "assets/images/dominik-jirovsky-re2LZOB2XvY-unsplash.jpg";
import parallaxImageImport2 from "assets/images/sven-schlager-xzjouTJASSA-unsplash.jpg";
import parallaxImageImport3 from "assets/images/kristy-kravchenko-Ng12EyF84Yw-unsplash.jpg";
import parallaxImageImport4 from "assets/images/paul-summers-ArtVJ-217Cw-unsplash.jpg";
import parallaxImageImport5 from "assets/images/sergei-a--heLWtuAN3c-unsplash.jpg";
import smartphone3Import from "assets/images/simba-522x1024-new.png";
import markerCampingImport from "assets/images/sleep_camping-150x150.png";
import markerWildernessHutImport from "assets/images/sleep_wilderness-hut-150x150.png";

const images = {
  appLogo: appLogoImport,
  appLogoSmall: appLogoSmallImport,
  burgerMenuIcon: burgerMenuIconImport,
  parallaxImage1: parallaxImageImport1,
  parallaxImage2: parallaxImageImport2,
  parallaxImage3: parallaxImageImport3,
  parallaxImage4: parallaxImageImport4,
  parallaxImage5: parallaxImageImport5,
  smartphoneTriple: smartphoneTripleImport,
  makerBathingPlace: markerBathingPlaceImport,
  markerFishingSpot: markerFishingSpotImport,
  smartphone: smartphoneImport,
  smartphone2: smartphone2Import,
  smartphone3: smartphone3Import,
  markerStarCarabiner: markerStarCarabinerImport,
  markerStarHammock: markerStarHammockImport,
  markerStarHut: markerStarHutImport,
  markerAttraction: markerAttractionImport,
  markerChargingStation: markerChargingStationImport,
  markerFoodStore: markerFoodStoreImport,
  markerGasStation: markerGasStationImport,
  markerToilet: markerToiletImport,
  markerViewpoint: markerViewpointImport,
  markerCamping: markerCampingImport,
  markerWildernessHut: markerWildernessHutImport,
};

export { images };
