import React from "react";
import TermsContent from "components/pages/terms/components/terms-content";
import TermsTitle from "components/pages/terms/components/terms-title";
import { useSearchParams } from "react-router-dom";

const TermsSection = () => {
  const [searchParams] = useSearchParams();
  const focusedParagraphNumber = searchParams.get("focusedParagraphNumber");
  return (
    <div className="prose mx-auto w-full max-w-5xl px-5 py-6 lg:py-16">
      {!focusedParagraphNumber && <TermsTitle />}
      <TermsContent />
    </div>
  );
};
export default TermsSection;
