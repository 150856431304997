import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/shared/button";
import * as React from "react";
import { useIntl } from "react-intl";

const appleAppStoreUrl = "https://apps.apple.com/us/app/heymdal/id1613144788";
const androidPlayStoreUrl =
  "https://play.google.com/store/apps/details?id=com.heymdalstudio.heymdal";

const openUrl = (url: string) => {
  window.open(url, "_blank");
};

const DownloadButtons = () => {
  const intl = useIntl();
  return (
    <div className="flex flex-wrap items-center justify-center space-x-3 space-y-3 space-x-reverse drop-shadow-2xl">
      <div />
      <Button
        text={intl.formatMessage({
          id: "forApple",
          defaultMessage: "For Apple",
        })}
        leading={<FontAwesomeIcon size="xl" icon={["fab", "apple"]} />}
        onTap={() => openUrl(appleAppStoreUrl)}
        rounded={true}
        className="py-4"
      />
      <Button
        text={intl.formatMessage({
          id: "forAndroid",
          defaultMessage: "For Android",
        })}
        leading={<FontAwesomeIcon size="lg" icon={["fab", "google-play"]} />}
        onTap={() => openUrl(androidPlayStoreUrl)}
        rounded={true}
        className="py-4"
      />
    </div>
  );
};

export default DownloadButtons;
