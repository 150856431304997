type Props = {
  title: string;
  subtitle: string;
  imagePath: string;
};

const FeaturesDetailItem = ({ title, subtitle, imagePath }: Props) => {
  return (
    <div className="mb-8 flex w-full flex-col items-center justify-center lg:mb-0 lg:max-w-xl lg:flex-row lg:px-8">
      <img
        src={imagePath}
        alt="Smart phones"
        className="w-[40%] drop-shadow-2xl"
      />
      <div className="prose w-[65%] lg:ml-6">
        <h2 className="mt-0 text-3xl">{title}</h2>
        <div className="max-w-sm text-lg">{subtitle}</div>
      </div>
    </div>
  );
};

export default FeaturesDetailItem;
