import * as React from "react";
import { images } from "utils/images";
import FeaturesDetailItem from "./feature-detail-item";

const FeaturesDetails = () => {
  return (
    <div className="flex flex-col lg:flex-row ">
      <FeaturesDetailItem
        title={"Finn steder du aldri har sett før"}
        subtitle={
          "Få lett oversikt over de stedene du er interessert i – rett i din bakgård."
        }
        imagePath={images.smartphone}
      />
      <FeaturesDetailItem
        title={"Ta vare på dine reiseminner"}
        subtitle={
          "Sjekk inn, ta bilder og lag nye markører av dine favorittsteder"
        }
        imagePath={images.smartphone2}
      />{" "}
      <FeaturesDetailItem
        title={"Del dine opplevelser"}
        subtitle={
          "Se hvor dine venner har vært, og del dine reiseruter og fineste steder"
        }
        imagePath={images.smartphone3}
      />
    </div>
  );
};

export default FeaturesDetails;
