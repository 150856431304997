import React from "react";

const FooterBottomSection = () => {
  return (
    <div className="flex flex-col justify-center space-y-2 bg-surfaceVariant px-2 py-6 text-center sm:flex-row sm:space-x-6 sm:space-y-0 md:p-6">
      <div className="flex justify-center space-x-1">
        <div>{"© 2023 Copyright:  "}</div>
        <div className="font-semibold text-onSurface">Heymdal Studio AS</div>
      </div>
      <div>{"Org.nr. 928958515"}</div>
    </div>
  );
};
export default FooterBottomSection;
