import TextInput from "components/pages/contact/components/text-input";
import Button from "components/shared/button";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";

const emailSubmissionUrl = "https://formspree.io/f/myyqgajp";

type Status = "SUCCESS" | "ERROR" | "INCORRECT" | "NONE";

const ContactSection = () => {
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);

  const intl = useIntl();
  const [status, setStatus] = useState<Status>("NONE");
  const [isLoading, setIsLoading] = useState(false);

  const getFormData = () => {
    return {
      name: nameRef.current?.value,
      email: emailRef.current?.value,
      message: messageRef.current?.value,
    };
  };
  const resetFormData = () => {
    nameRef.current!.value = "";
    emailRef.current!.value = "";
    messageRef.current!.value = "";
  };

  const checkIfAllFieldsAreFilled = () => {
    const name = nameRef.current?.value;
    const email = emailRef.current?.value;
    const message = messageRef.current?.value;

    const allFieldsAreFilled = name && email && message;

    if (!allFieldsAreFilled) setStatus("INCORRECT");
    return allFieldsAreFilled;
  };

  const handleSubmit = async () => {
    if (!checkIfAllFieldsAreFilled()) return;
    setIsLoading(true);
    await fetch(emailSubmissionUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(getFormData()),
    })
      .then((response) => {
        if (response.ok) {
          setStatus("SUCCESS");
          resetFormData();
        } else {
          setStatus("ERROR");
        }
      })
      .catch((error) => console.log(error));
    setIsLoading(false);
  };

  return (
    <div className="prose mx-auto w-full max-w-3xl px-5 py-16">
      {status === "SUCCESS" ? (
        <>
          <h1>{intl.formatMessage({ id: "messageSent" })})</h1>
          <p className="text-green-500">Thank you for contacting us!</p>
        </>
      ) : (
        <>
          <h1> {intl.formatMessage({ id: "contactInfo" })}</h1>
          <p className="mb-8">
            {intl.formatMessage({ id: "contactInfoDescription1" })}
          </p>
          <TextInput
            placeholder={intl.formatMessage({ id: "name" })}
            inputRef={nameRef}
          />
          <TextInput
            placeholder={intl.formatMessage({ id: "email" })}
            inputRef={emailRef}
          />
          <TextInput
            placeholder={intl.formatMessage({ id: "message" })}
            isTextArea={true}
            textAreaRef={messageRef}
          />
          {status === "ERROR" && (
            <p className="mt-0 text-red-500">Oops! There was an error.</p>
          )}{" "}
          {status === "INCORRECT" && (
            <p className="mt-0 text-red-500">You must fill all fields.</p>
          )}
          <Button
            onTap={handleSubmit}
            text={intl.formatMessage({ id: "send" })}
            className="w-full py-1"
            isLoading={isLoading}
          />
        </>
      )}
    </div>
  );
};
export default ContactSection;
