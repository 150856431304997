import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faCalendar,
  faCar,
  faCarSide,
  faCheckSquare,
  faChevronDown,
  faCircle,
  faClock,
  faHandshakeSimple,
  faMoneyCheck,
  faRefresh,
  faRunning,
  faSeedling,
  faSpinner,
  faTasks,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "components/footer/footer";
import NavBar from "components/nav-bar/nav-bar";
import AboutPage from "components/pages/about/about-page";
import ContactPage from "components/pages/contact/contact-page";
import HomePage from "components/pages/home/home-page";
import PageNotFound from "components/pages/page-not-found";
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { paths } from "utils/routes";
import TermsPage from "components/pages/terms/terms-page";
import PrivacyPage from "components/pages/privacy/privacy-page";

library.add(
  fab,
  faCheckSquare,
  faCalendar,
  faMoneyCheck,
  faCar,
  faClock,
  faRunning,
  faTasks,
  faCircle,
  faChevronDown,
  faSpinner,
  faSeedling,
  faUsers,
  faHandshakeSimple,
  faRefresh,
  faCarSide
);

function App() {
  return (
    <div className="flex h-screen flex-col">
      <NavBar />
      <Routes>
        <Route path={paths.home} element={<HomePage />} />
        <Route path={paths.about} element={<AboutPage />} />
        <Route path={paths.contact} element={<ContactPage />} />
        <Route path={paths.terms} element={<TermsPage />} />
        <Route path={paths.privacy} element={<PrivacyPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
