import FeaturesDetails from "components/pages/home/components/features-section/components/feature-details";
import * as React from "react";

const FeaturesSection = () => {
  return (
    <div className="flex flex-col items-center justify-center space-y-16 pb-8 pt-24 lg:pb-16">
      <FeaturesDetails />
    </div>
  );
};

export default FeaturesSection;
