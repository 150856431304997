import MainSection from "components/pages/home/components/main-section/main-section";
import * as React from "react";
import FeaturesSection from "components/pages/home/components/features-section/features-section";

const HomePage = () => {
  return (
    <div>
      <MainSection />
      {/*<FaqSection />*/}
      <FeaturesSection />
      {/*<StatsSection />*/}
      {/*<DownloadSummarySection />*/}
    </div>
  );
};

export default HomePage;
