import * as React from "react";

import { useIntl } from "react-intl";
import { ParallaxBanner } from "react-scroll-parallax";
import { images } from "utils/images";

const AboutSection = () => {
  const intl = useIntl();

  return (
    <div className="relative ">
      <div className="absolute inset-0 z-10 flex w-full items-center justify-center ">
        <div className="flex w-full max-w-6xl flex-col items-center justify-between space-y-10 px-8 lg:flex-row">
          <div className="space-y-4 text-white">
            <div className="mb-4 text-5xl font-bold lg:text-3xl ">
              Appen for eventyrlystne friluftere
            </div>
            <div>
              Vi i Heymdal tror på å gjøre friluftsliv og naturen mer
              tilgjengelig for alle gjennom appen vår. Vårt mål er å hjelpe
              andre mennesker få mer ut av naturen. Alt fra overnatting i telt
              og hengekøye til friluftsaktiviter som klatring, bading eller
              fiske.
            </div>
            <div>
              Heymdal tilbyr en oversikt over unike steder i hele landet. Dataen
              har vi delvis samlet inn selv og resten er delt av våre brukere.
              Ved å bruke appen vil man ta vare på sin reiselogg, som gjør det
              enkelt å dele opplevelser med familie og venner.
            </div>
            <div>
              Idéen kom til lys for første gang sommeren 2021, som et resultat
              av mye frustrasjon grunnet mangel på kjennskap til lokale
              friluftsperler og overnattingssteder da vi vår på reise. Hvorfor
              var det ingen lett måte å finne ut hvor teltet skal stå, eller den
              beste plassen å nyte utsikten?
            </div>
          </div>
          <img
            src={images.appLogoSmall}
            alt="Smart phones"
            className="w-full max-w-xs px-16 drop-shadow-2xl md:px-0 lg:ml-32 lg:max-w-sm"
          />
        </div>
      </div>

      <ParallaxBanner
        layers={[{ image: images.parallaxImage5, speed: -15 }]}
        className="h-[1100px] brightness-75 lg:h-[800px]"
      />
    </div>
  );

  // <div className="prose mx-auto flex w-full max-w-6xl flex-col items-center px-5 py-16 md:flex-row">
  //   <div className="w-full whitespace-break-spaces p-0 md:w-1/2 md:pr-16">
  //     <h1>{intl.formatMessage({ id: "about" })}</h1>
  //     <p>{intl.formatMessage({ id: "aboutUsDescription1" })}</p>
  //   </div>
  //   <div className="flex justify-center px-8 py-16 lg:px-16">
  //     <FontAwesomeIcon
  //       size="7x"
  //       icon={"seedling"}
  //       className="pr-10 text-primary"
  //     />
  //     <FontAwesomeIcon size="7x" icon={"car-side"} className="text-primary" />
  //   </div>
  //   {/*<img*/}
  //   {/*  src={howItWorksIllustrations.forPassengers1}*/}
  //   {/*  alt="Smart phones"*/}
  //   {/*  className="w-full max-w-none drop-shadow-2xl sm:max-w-md md:w-1/2 md:max-w-lg"*/}
  //   {/*/>*/}
  // </div>
};

export default AboutSection;
