import NavBarItems from "components/nav-bar/components/nav-bar-items";
import React from "react";
import clsx from "clsx";

type Props = {
  className?: string;
};

const DropdownMenu = ({ className }: Props) => {
  return (
    <div className={clsx(className, "bg-surface shadow-xl")}>
      <NavBarItems />
      <div className="h-8" />
      {/*<div className="m-5 mt-0 flex justify-between border-t-2  pt-5">*/}
      {/*  <SocialMediaLinks />*/}
      {/*  <ChangeLanguageDropdown />*/}
      {/*</div>*/}
    </div>
  );
};
export default DropdownMenu;
