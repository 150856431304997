import React from "react";
import Divider from "components/shared/divider";

const PrivacyTitle = () => {
  return (
    <div className="prose max-w-5xl">
      <div className="italic">{"Last modified: 15.05.2022"}</div>
      <Divider />
      <h1 className="m-0 mb-2 text-3xl text-slate-700">Privacy Policy</h1>
      <div className="whitespace-break-spaces text-sm ">
        {
          "In order to be able to use any of the services on HEYMDAL (hereinafter collectively referred to as the “Service”), you must give us access to information about you. In this privacy statement, you can read how we collect and process your personal information."
        }
      </div>
      <Divider />
    </div>
  );
};
export default PrivacyTitle;
