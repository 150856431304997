import FooterBottomSection from "components/footer/components/footer-bottom-section";
import FooterMainLinks from "components/footer/components/footer-main-links";
import React from "react";
import {NavLink, useSearchParams} from "react-router-dom";
import {images} from "utils/images";
import {paths} from "utils/routes";

const Footer = () => {
  const [searchParams] = useSearchParams();
  const hideFooter = searchParams.get("hideFooter") === "true";

  return hideFooter ? null : (
    <div className="mt-auto w-full bg-surface text-onSurface">
      <div className="mx-auto flex max-w-6xl flex-col items-center justify-between px-5 py-8 md:flex-row md:py-6">
        <NavLink to={paths.home} className="pb-6">
          <img src={images.appLogo} alt="App logo" className="w-32" />
        </NavLink>
        {/*<SocialMediaLinks className="py-10" />*/}
        <FooterMainLinks />
      </div>
      <FooterBottomSection />
    </div>
  );
};
export default Footer;
