import DownloadButtons from "components/shared/download-buttons";
import * as React from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import { images } from "utils/images";
import MainTexts from "components/pages/home/components/main-section/components/main-texts";

const imagePaths = [
  images.parallaxImage2,
  images.parallaxImage3,
  images.parallaxImage4,
];

const getRandomImagePath = () => {
  const randomIndex = Math.floor(Math.random() * imagePaths.length);
  return imagePaths[randomIndex];
};

const MainSection = () => {
  return (
    <div className="relative ">
      <div className="absolute inset-0 z-10 flex w-full items-center justify-center ">
        <div className="flex w-full max-w-6xl flex-col items-center justify-between space-y-10 px-8 lg:flex-row">
          <div className="space-y-10">
            <MainTexts />
            <DownloadButtons />
          </div>
          <img
            src={images.smartphoneTriple}
            alt="Smart phones"
            className="md:max-w-m w-full max-w-xs drop-shadow-2xl lg:max-w-lg"
          />
        </div>
      </div>
      <ParallaxBanner
        layers={[{ image: getRandomImagePath(), speed: -15 }]}
        className="h-[1100px] lg:h-[800px]"
      />
    </div>
  );
};

export default MainSection;
