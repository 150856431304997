import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { getLanguageMessages, languageCodes } from "i18n/messages";
import { createIntl, createIntlCache, RawIntlProvider } from "react-intl";
import { useSearchParams } from "react-router-dom";

type ContextProps<P = unknown> = P & {
  locale: string;
  changeLanguage: (newLocale: string) => void;
};

const defaultLocale = languageCodes.norwegian;
export const Context = React.createContext<ContextProps>({
  locale: defaultLocale,
  changeLanguage: () => {},
});

export const cache = createIntlCache();
/** You can use this variable in other files even after reassigning it. */
export let intl = createIntl(
  { locale: defaultLocale, messages: getLanguageMessages(defaultLocale) },
  cache
);
export let fmt = intl.formatMessage;

export const languageQueryParamName = "lang";
const CustomIntlProvider = (props: PropsWithChildren) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const urlParamLanguage = searchParams.get(languageQueryParamName);
  const [locale, setLocale] = useState(defaultLocale);
  const [firstMount, setFirstMount] = useState(true);

  const changeLanguage = useCallback(
    (newLocale: string): void => {
      intl = createIntl(
        { locale: newLocale, messages: getLanguageMessages(newLocale) },
        cache
      );
      fmt = intl.formatMessage;
      document.documentElement.lang = newLocale;
      setLocale(newLocale);
      const hideFooter = searchParams.get("hideFooter") === "true";
      const hideNavBar = searchParams.get("hideNavBar") === "true";

      // Must be same as languageQueryParamName
      const newParams = { lang: newLocale };
      if (hideNavBar) Object.assign(newParams, { hideNavBar: "true" });
      if (hideFooter) Object.assign(newParams, { hideFooter: "true" });
      setSearchParams(newParams);
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    if (firstMount && urlParamLanguage && urlParamLanguage !== defaultLocale) {
      changeLanguage(urlParamLanguage);
      setFirstMount(false);
    }
  }, [changeLanguage, firstMount, urlParamLanguage]);

  return (
    <Context.Provider value={{ locale, changeLanguage }}>
      <RawIntlProvider value={intl}>{props.children}</RawIntlProvider>
    </Context.Provider>
  );
};

export default CustomIntlProvider;
