import React from "react";

type Props = {
  value?: string;
  onChange?: (value: string) => void;
  placeholder: string;
  isTextArea?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  textAreaRef?: React.Ref<HTMLTextAreaElement>;
};

const TextInput = ({
  value,
  onChange,
  placeholder,
  isTextArea = false,
  inputRef,
  textAreaRef,
}: Props) => {
  const inputStyle =
    "focus:shadow-outline mb-4 w-full appearance-none rounded border px-3 py-2 text-gray-700 shadow focus:outline-none";
  return isTextArea ? (
    <textarea
      className={inputStyle}
      id={placeholder}
      placeholder={placeholder}
      value={value}
      onChange={onChange ? (e) => onChange(e.target.value) : undefined}
      rows={4}
      ref={textAreaRef}
    />
  ) : (
    <input
      className={inputStyle}
      value={value}
      id={placeholder}
      type="text"
      placeholder={placeholder}
      onChange={onChange ? (e) => onChange(e.target.value) : undefined}
      ref={inputRef}
    />
  );
};
export default TextInput;
