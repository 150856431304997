import * as React from "react";
import clsx from "clsx";
import { images } from "utils/images";

const MainTexts = () => {
  const subtitleShadow = "[text-shadow:_0_0_30px_rgb(0_0_0_/_100%)]";

  return (
    <div className="flex flex-col items-center space-y-7 text-center">
      <img
        src={images.appLogoSmall}
        alt="Smart phones"
        className="w-32 drop-shadow-2xl lg:max-w-sm"
      />
      <div className="mb-4 text-4xl font-bold uppercase text-white lg:text-5xl ">
        Oppdag lokale hemmeligheter
      </div>
      <div
        className={clsx(
          subtitleShadow,
          "max-w-sm space-y-5 p-0 text-lg text-white"
        )}
      >
        {/*<div>{intl.formatMessage({id: "mainTitleDescription1"})}</div>*/}
        {/*<div>{intl.formatMessage({id: "mainTitleDescription2"})}</div>*/}
      </div>
    </div>
  );
};

export default MainTexts;
